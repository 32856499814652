import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify';
import React from "react";

Amplify.configure({
  // OPTIONAL - if your API requires authentication
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
  //  identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    // REQUIRED - Amazon Cognito Region
  //  region: 'XX-XXXX-X',
    // OPTIONAL - Amazon Cognito User Pool ID
  //  userPoolId: 'XX-XXXX-X_abcd1234',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  //  userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
  },
  API: {
    endpoints: [
      {
        name: "covid19",
        endpoint: "https://covid19.garofaln.people.aws.dev/covid"
      },
      {
        name: "openair",
        endpoint: "https://openair.garofaln.people.aws.dev/air"
      }
    ]
  }
});

class Display extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "<Your data will appear here>"
    }
  }

  covid = (path) => {
    const that = this;
    const apiName = 'covid19';
    const myInit = {
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };

    API.get(apiName, path, myInit)
        .then(response => {
          that.setState({
            data: JSON.stringify(response)
          });
        })
        .catch(error => {
          console.log(error.response);
        });
  }

  openair = (path) => {
    const that = this;
    const apiName = 'openair';
    const myInit = {
      headers: {},
      response: false,
      queryStringParameters: {}
    };

    API.get(apiName, path, myInit)
        .then(response => {
          that.setState({
            data: JSON.stringify(response)
          });
        })
        .catch(error => {
          console.log(error.response);
        })
  }

  render() {
    return (
      <div className="Display">
        <ul className="List">
          <li><button onClick={this.covid.bind(this, '/total')}>Get Sum-Total Covid Data</button></li>
          <li><button onClick={this.covid.bind(this, '/')}>Get Worldwide Covid Data</button></li>
          <li><button onClick={this.covid.bind(this, '/seattle')}>Get Covid Data for Seattle</button></li>
          <li><button onClick={this.covid.bind(this, '/us')}>Get Covid Data for the U.S.</button></li>
          <li><button onClick={this.covid.bind(this, '/us/timeline')}>Get History of Covid in the U.S.</button></li>
        </ul>
        <ul className="List">
          <li><button onClick={this.openair.bind(this, '/seattle')}>Get Air Pollution Data for Seattle</button></li>
          <li><button onClick={this.openair.bind(this, '/v2/locations')}>Get Unspecified Air Pollution Data</button></li>
        </ul>
        <p className="Output">{this.state.data}</p>
      </div>
    );
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hello there!</h1>
        <h3>I'm Nicco and this is my API!</h3>
        <h4>Click one of the buttons below to review some open source data</h4>
        <Display className="Display"/>
      </header>
    </div>
  );
}

export default App;
